import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14')
];

export const server_loads = [0];

export const dictionary = {
		"/": [4],
		"/account/password": [5,[2]],
		"/account/profile": [6,[2]],
		"/audio": [7],
		"/login": [8],
		"/register": [9],
		"/rooms": [10,[3]],
		"/rooms/chat/[uuid]": [11,[3]],
		"/rooms/chat/[uuid]/invite": [12,[3]],
		"/rooms/new": [13,[3]],
		"/success": [14]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';